import React from 'react';
import { Image } from '@mantine/core';
import privacy from '../images/privacy.jpg';
import './privacy.less';
import SEO from "../components/seo";

function Privacy() {
  return (<div className='privacy'>
    <section className="bg">
      <Image src={privacy} height="100vh" width="100vw" />
      <div className="text-mask">
        <span className="text">隐私政策</span>
      </div>
    </section>
    <section>
      <div className='content'>
        <p>保护您的私人信息是我们的首要任务。本隐私声明适用于<a href="https://www.chengyangtech.com">https://www.chengyangtech.com</a> 和上海橙氧科技有限公司管理数据收集和使用。就本隐私政策而言，除非另有说明，所有提及上海橙氧科技有限公司的引用都来自于<a href="https://www.chengyangtech.com">https://www.chengyangtech.com</a>。上海橙氧科技有限公司网页是一个新闻和信息网站。通过使用上海橙氧科技有限公司的网站，您同意本声明中描述的数据服务。</p>
        <h3>收集您的个人信息</h3>
        <p>除非您自愿提供给我们，否则我们不会收集您的任何个人信息。但当您选择使用本网站提供的某些产品或服务时，您可能需要向我们提供某些个人资料。这些可能包括：（a）在本网站注册账户；（b）参加由我们或我们的合作伙伴赞助的抽奖或竞赛；（c）签约接受选定的第三方的特别优惠；（d）向我们发送电子邮件；（e）在本网站订购和购买产品及服务时提交您的信用卡和付款信息。即，我们将使用您的信息，但不限于，就您要求我们提供服务和/或产品与您进行沟通。我们也可能在未来收集更多的跟人或非个人信息。</p>
        <h3>与第三方共享信息</h3>
        <p>上海橙氧科技有限公司可能会不时地代表外部业务合作伙伴与您联系，了解您可能感兴趣的特定产品。在这些情况下，您唯一的个人身份信息（电子邮件、姓名、地址、电话号码）不会转移给第三方。上海橙氧科技有限公司可以与可信的合作伙伴共享数据，以帮助执行统计分析、发送电子邮件或邮政邮件、提供客户支持或安排交付。除了为上海橙氧科技有限公司提供这些服务外，所有这些第三方都被禁止使用您的个人信息，并且他们被要求对您的信息保密。</p>
        <p>上海橙氧科技有限公司可能会再不事先通知的情况下披露您的个人信息，如果法律要求或处于善意认为这样做是必要的：（a）遵守橙氧科技有限公司或本网站提供的法律法规或法律程序；（b）保护和捍卫橙氧科技有限公司的权利或财产；和/或（c）在紧急情况下采取行动，以保护橙氧科技有限公司使用者或公众的人身安全。</p>
        <h3>跟踪用户行为</h3>
        <p>上海橙氧科技有限公司可以跟踪网站和页面，我们的用户访问橙氧科技有限公司网站，以确定哪些服务点击量最多。这些数据用于橙氧科技有限公司向那些行为表面他们对特定主题领域感兴趣的客户交付定制的内容和广告。</p>
        <h3>自动收集信息</h3>
        <p>有关您的计算机硬件和软件的信息可能会由橙氧科技有限公司自动收集。这些信息可以包括：你的IP地址，浏览器类型，域名，访问时间和参考网站地址。此信息用于服务的运行，维持服务质量，并提供有关上海橙氧科技有限公司网站使用的一般统计数据。</p>
        <h3>您的个人信息安全</h3>
        <p>上海橙氧科技有限公司保护您的个人信息，从未经授权的访问，使用，或披露。橙氧科技为此使用以下方法：SSL协议</p>
        <p>当个人资料（例如信用卡号码）传送到其他网站时，会透过加密（例如安全套接字层（SSL）协议）加以保护。</p>
        <p>我们努力采取适当的安全措施，以防止未经授权的访问或更改您的个人信息。不幸的是，通过互联网或任何无线网络传输的数据都不能保证100%安全。因此，虽然我们努力保护您的个人信息，但您承认:(a)互联网存在我们无法控制的固有安全和隐私限制;您和我们通过本网站交换的任何和所有信息和数据的安全性、完整性和隐私不能得到保证。</p>
        <h3>电子邮件通信</h3>
        <p>上海橙氧科技有限公司可能会不时通过电子邮件与您联系，以提供公告、促销优惠、提醒、确认、调查和/或其他一般沟通。</p>
        <h3>对该条款的更改</h3>
        <p>上海橙氧科技有限公司保留随时更改本隐私政策的权利。我们将通过向您帐户中指定的主要电子邮件地址发送通知、在我们的网站上发布显著通知以及/或更新本页上的任何隐私信息等方式通知您我们处理个人信息方式的重大变化。您在修改后继续使用本网站和/或通过本网站提供的服务将构成您:(a)确认修改后的隐私政策;及(b)遵守及受该政策约束的协议。</p>
        <h3>联系信息</h3>
        <p>
          欢迎您提供关于隐私声明的问题或评论。如果您认为橙氧科技没有遵守这一声明，请联系:<br />
          上海橙氧科技有限公司<br />
          上海市宝山区长江路555号
        </p>
      </div>
    </section>
  </div>);
}

export default Privacy;

export const Head = () => (<SEO />)
