import React from "react"
import useSiteMetadata from "../hooks/use-site-metadata";

export default ({ title, description, pathname, children }: {
  title?: string,
  description?: string,
  pathname?: string,
  children?: React.ReactNode
}) => {
  const { title: defaultTitle, description: defaultDescription, keywords } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    keywords
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      {children}
    </>
  )
}